"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWalle = exports.w_catch = exports.registerWalle = exports.Wall_eError = exports.Wall_e = void 0;
const Wall_e_1 = require("./classes/Wall_e");
Object.defineProperty(exports, "Wall_e", { enumerable: true, get: function () { return Wall_e_1.Wall_e; } });
const wall_eError_1 = require("./classes/wall_eError");
Object.defineProperty(exports, "Wall_eError", { enumerable: true, get: function () { return wall_eError_1.Wall_eError; } });
let walle;
function registerWalle(register, serviceBundle, showErrorOnConsole = false) {
    const w = new Wall_e_1.Wall_e(serviceBundle, showErrorOnConsole);
    walle = register(w);
}
exports.registerWalle = registerWalle;
function w_catch(error) {
    if (walle === undefined) {
        console.error("Wall-e is not initialized!");
    }
    else {
        walle.handle(error);
    }
}
exports.w_catch = w_catch;
function getWalle() {
    return walle;
}
exports.getWalle = getWalle;
