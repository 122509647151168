"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wall_eError = void 0;
class Wall_eError {
    constructor() {
        this.message = null;
        this.beforeAction = null;
        this.action = null;
        this.afterAction = null;
    }
}
exports.Wall_eError = Wall_eError;
