"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultError = exports.ConnectionError = exports.ServerError = void 0;
const wall_eError_1 = require("./classes/wall_eError");
class ServerError extends wall_eError_1.Wall_eError {
    constructor() {
        super(...arguments);
        this.message = "Pareix que ara mateix hi ha alguns problemes als nostres servidors, si us plau, torna a provar-ho en una estona.";
    }
}
exports.ServerError = ServerError;
class ConnectionError extends wall_eError_1.Wall_eError {
    constructor() {
        super(...arguments);
        this.message = "Estem tenint dificultats per connectar-nos als nostres servidors, si us plau, revisa la teva connexió.";
    }
}
exports.ConnectionError = ConnectionError;
class DefaultError extends wall_eError_1.Wall_eError {
    constructor() {
        super(...arguments);
        this.message = "Si us plau, torna-ho a provar. Si continua sortint aquest error, posa't en contacte amb nosaltres.";
    }
}
exports.DefaultError = DefaultError;
