"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wall_e = void 0;
const defaultErrors_1 = require("../defaultErrors");
const wall_eError_1 = require("./wall_eError");
class Wall_e {
    constructor(serviceBundle, showErrorOnConsole = false) {
        this.serviceBundle = serviceBundle;
        this.showErrorOnConsole = showErrorOnConsole;
        this.errors = [];
    }
    register(errorTrigger) {
        this.errors.push(errorTrigger);
        return this;
    }
    getRegisteredErrors() {
        return this.errors;
    }
    async handle(error) {
        if (this.showErrorOnConsole) {
            console.error(error);
        }
        let walleError;
        if (!(error instanceof wall_eError_1.Wall_eError)) {
            walleError = this.getError(error);
        }
        else {
            walleError = error;
        }
        if (walleError.beforeAction) {
            await walleError.beforeAction(this.serviceBundle);
        }
        if (walleError.action) {
            await walleError.action(this.serviceBundle);
        }
        if (walleError.afterAction) {
            await walleError.afterAction(this.serviceBundle);
        }
    }
    getError(error) {
        let userError = null;
        for (const trigger of this.errors) {
            userError = trigger(error);
            if (userError) {
                break;
            }
        }
        if (!userError) {
            return new defaultErrors_1.DefaultError();
        }
        return userError;
    }
}
exports.Wall_e = Wall_e;
